const majors: { [key: string]: string[] } = {
    Arts: [
        'Ancient and Medieval History',
        'Anthropology, Social and Cultural',
        'Anthropology',
        'Archaeology',
        'Art History',
        'Canadian Studies',
        'Communication and Culture',
        'Communication and Media Studies',
        'Dance',
        'Development Studies',
        'Drama',
        'Earth Science',
        'East Asian Language Studies',
        'East Asian Studies',
        'Economics',
        'English',
        'Film Studies',
        'French',
        'Geography',
        'German',
        'Greek and Roman Studies',
        'History',
        'International Indigenous Studies',
        'International Relations',
        'Italian Studies',
        'Latin American Studies',
        'Law and Society',
        'Linguistics',
        'Linguistics and Language',
        'Music',
        'Philosophy',
        'Political Science',
        'Psychology',
        'Religious Studies',
        'Religious Studies and Applied Ethics',
        'Russian',
        'Sociology',
        'Spanish',
        'Urban Studies',
        'Visual Studies',
        'Women’s Studies',
    ],
    'Cumming School of Medicine': ['Bioinformatics', 'Biomedical Science', 'Health and Society', 'Doctor of Medicine'],
    'Haskayne School of Business': ['Commerce'],
    Kinesiology: [
        'Kinesiology',
        'Leadership in Pedagogy and Coaching',
        'Mind Sciences in Kinesiology',
        'Biomechanics',
        'Exercise and Health Physiology',
        'Kinesiology',
        'Mind Sciences in Kinesiology',
    ],
    Law: ['Law'],
    Nursing: ['Nursing'],
    'Schulich School of Engineering': [
        'Chemical Engineering',
        'Civil Engineering',
        'Electrical Engineering',
        'Energy Engineering',
        'Geomatics Engineering',
        'Mechanical Engineering',
        'Oil and Gas Engineering',
        'Software Engineering',
    ],
    Science: [
        'Computer Science',
        'Actuarial Science',
        'Applied and Environmental Geology',
        'Applied Chemistry',
        'Applied Mathematics',
        'Astrophysics',
        'Biochemistry',
        'Biological Sciences',
        'Cellular, Molecular and Microbial Biology',
        'Chemical Physics',
        'Chemistry',
        'Computer Science',
        'Ecology',
        'Environmental Science',
        'General Mathematics',
        'Geology',
        'Geophysics',
        'Mathematics',
        'Natural Sciences',
        'Neuroscience',
        'Physics',
        'Plant Biology',
        'Pure Mathematics',
        'Statistics',
        'Zoology',
    ],
    'Social Work': ['Social Work'],
    'Veterinary Medicine': ['Doctor of Veterinary Medicine'],
    'Werklund School of Education': ['2 Year Secondary', '2 Year Elementary', '5 Year Secondary', '5 Year Elementary'],
};

export default majors;
